import { Suspense, lazy } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import ScrollToTop from './components/scrollToTop';
import LoadingState from './components/loadingState';
function App() {
  const Home = lazy(()=>import('./pages/home'))
  const Recomendation = lazy(()=>import('./pages/home/recomendation'))

  return (
    <BrowserRouter>
      <Suspense fallback={<LoadingState/>}>
        <ScrollToTop behavior="instant" />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/recomendation" element={<Recomendation />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
