import React from "react";
import { Dropdown } from "flowbite-react";
import { GlobeAltIcon } from "@heroicons/react/24/outline";
import i18n from "../../i18n";

const LanguageSwitcher = () => {
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <Dropdown
      label=""
      closeInside={true}
      renderTrigger={() => (
        <GlobeAltIcon className="w-5 text-gray-500 hover:text-warning" />
      )}
    >
      <Dropdown.Item onClick={() => changeLanguage("id")}>
        <img
          src="/assets/icon/lang/id.jpg"
          alt=""
          className="h-6 w-10 rounded me-4"
        />
        Indonesia
      </Dropdown.Item>
      <Dropdown.Item onClick={() => changeLanguage("en")}>
        <img
          src="/assets/icon/lang/en.jpg"
          alt=""
          className="h-6 w-10 rounded me-4"
        />
        English
      </Dropdown.Item>
      <Dropdown.Item onClick={() => changeLanguage("ar")}>
        <img
          src="/assets/icon/lang/ar.jpg"
          alt=""
          className="h-6 w-10 rounded me-4"
        />
        Arabic
      </Dropdown.Item>
      <Dropdown.Item onClick={() => changeLanguage("th")}>
        <img
          src="/assets/icon/lang/th.jpg"
          alt=""
          className="h-6 w-10 rounded me-4"
        />
        Thailand
      </Dropdown.Item>
      <Dropdown.Item onClick={() => changeLanguage("cn")}>
        <img
          src="/assets/icon/lang/cn.jpg"
          alt=""
          className="h-6 w-10 rounded me-4"
        />
        China
      </Dropdown.Item>
    </Dropdown>
  );
};

export default LanguageSwitcher;
