import { Navbar } from "../components/navbar";
import Footer from "../components/footer";
import Jumbotron from "../components/jumbotron";
import { Accordion } from "flowbite-react";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function App({ title, children }) {
   return (
     <main>
       <Navbar />
       <div className="mx-auto w-full max-w-7xl mt-28 mb-16 px-5 sm:px-10 md:px-20">
         {children}
       <section className="mb-16 sm:mb-24 md:mb-32 flex flex-col sm:flex-row gap-8 sm:gap-12 ">
         <div className="w-full sm:w-1/2">
           <span className="p-2 border-2 rounded-md border-blue-100 text-blue-400 text-xs text-nowrap w-fit h-fit">
             FAQ
           </span>
           <h1 className="font-medium leading-tight md:leading-snug lg:leading-normal text-3xl md:text-4xl lg:text-5xl mt-4 mb-8">
             Pertanyaan Yang Sering Diajukan
           </h1>
           <p className="text-gray-500">
              Berikut adalah beberapa pertanyaan yang sering diajukan tentang platform ini.
           </p>
         </div>
         <div className="w-full sm:w-1/2">
           <Accordion className="border-none" collapseAll>
             <Accordion.Panel>
               <Accordion.Title>
                 Bagaimana cara platform ini mengukur KPI?
               </Accordion.Title>
               <Accordion.Content className="">
                 <p className="mb-2 text-gray-500 dark:text-gray-400">
                    Platform ini menggunakan metode perhitungan yang telah terbukti efektif dalam mengukur KPI.
                 </p>
               </Accordion.Content>
             </Accordion.Panel>
             <Accordion.Panel>
               <Accordion.Title>
                 Apakah saya bisa menyesuaikan KPI yang ingin diukur?
               </Accordion.Title>
               <Accordion.Content>
                 <p className="mb-2 text-gray-500 dark:text-gray-400">
                    Ya, Anda bisa menyesuaikan KPI sesuai dengan kebutuhan bisnis Anda.
                 </p>
               </Accordion.Content>
             </Accordion.Panel>
             <Accordion.Panel>
               <Accordion.Title>
                 Bagaimana dengan keamanan data saya?
               </Accordion.Title>
               <Accordion.Content>
                 <p className="mb-2 text-gray-500 dark:text-gray-400">
                    Keamanan data Anda adalah prioritas kami. Kami menggunakan enkripsi data untuk melindungi data Anda.
                 </p>
               </Accordion.Content>
             </Accordion.Panel>
             <Accordion.Panel>
               <Accordion.Title>
                 Apakah saya bisa menyesuaikan KPI yang ingin diukur?
               </Accordion.Title>
               <Accordion.Content>
                 <p className="mb-2 text-gray-500 dark:text-gray-400">
                    Ya, Anda bisa menyesuaikan KPI sesuai dengan kebutuhan bisnis Anda.
                 </p>
               </Accordion.Content>
             </Accordion.Panel>
             <Accordion.Panel>
               <Accordion.Title>
                 Bagaimana cara platform ini mengukur KPI?
               </Accordion.Title>
               <Accordion.Content>
                 <p className="mb-2 text-gray-500 dark:text-gray-400">
                    Platform ini menggunakan metode perhitungan yang telah terbukti efektif dalam mengukur KPI.
                 </p>
               </Accordion.Content>
             </Accordion.Panel>
           </Accordion>
         </div>
       </section>
       </div>
       <Jumbotron
         className="border-2 border-warning bg-cover bg-center mb-16 sm:mb-24 md:mb-32 rounded-3xl mx-5 sm:mx-10 md:mx-28"
         style={{ backgroundImage: "url('/assets/img/modern-5.png')" }}
         title="Get started with your KPI Software"
         subtitle="Start your free trial today, no credit card required."
         buttons={[
           {
             label: "Take your KPIs for a test run",
             href: "#",
             className: "bg-warning text-white text-sm px-8 py-2 rounded-full",
              onClick: (event) => {
                event.preventDefault();
                const currentPath = window.location.pathname;

                let offsetTop;
                if (currentPath === '/') {
                  offsetTop = 300;
                } else if (currentPath === '/recommendation') {
                  offsetTop = 0;
                } else {
                  offsetTop = 0;
                }
                window.scrollTo({
                  top: offsetTop,
                  behavior: 'smooth'
                });
              }
           },
         ]}
       ></Jumbotron>

       <Footer />
       <ToastContainer />
     </main>
   );
}