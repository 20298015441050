import React from "react";

const Footer = () => {
  return (
    <main>
      <footer className="footer px-5 sm:px-10 md:px-20 py-8 bg-[#FFF7ED]">
        <div className="md:flex md:justify-between md:space-x-16">
          <div className="w-fit">
            <a href="#" className="flex items-center space-x-1 mb-6">
              <img
                alt="Hulutalent Logo"
                src="assets/icon/hulutalent.png"
                className="hulutalent-logo w-6 h-6"
              />
              <p className="text-nowrap font-bold text-dark-tosca">
                Hulu Talent
              </p>
            </a>
          </div>
          <div className="address">
            <div className="address-1 mb-6">
              <h2 className="mb-3 text-sm font-semibold text-gray-900 dark:text-white">
                Jakarta
              </h2>
              <p className="text-xs text-gray-500 mb-0">
                Office 8 Building, Jl. Senopati No.8, RT.8/RW.3, Senayan, Kec.
                Kby. Baru, Kota Jakarta Selatan, Daerah Khusus Ibukota Jakarta
                12190
              </p>
            </div>
            <div className="address-2 mb-6">
              <h2 className="mb-3 text-sm font-semibold text-gray-900 dark:text-white">
                Banjarmasin
              </h2>
              <p className="text-xs text-gray-500 mb-0">
                Jl. A. Yani No.KM 3,5, Kebun Bunga, Kec. Banjarmasin Tim., Kota
                Banjarmasin, Kalimantan Selatan 70237
              </p>
            </div>
          </div>
          <div className="Features">
            <div>
              <h2 className="mb-3 text-sm font-semibold text-gray-900 dark:text-white">
                Features
              </h2>
              <ul className="flex flex-col items-start text-xs text-gray-500">
                <li className="mb-3">Employee Database</li>
                <li className="mb-3">Hierarchy</li>
                <li className="mb-3">Key Performance Indicator</li>
                <li className="mb-3">Assessment</li>
                <li className="mb-3">Result</li>
              </ul>
            </div>
          </div>
        </div>
        <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
        <div className="sm:flex sm:items-center sm:justify-between">
          <h4 className="copyright text-xs text-gray-500">
            © 2024 PT. Cipta Daya Inovasi - All Rights Reserved
          </h4>
          <div className="footer-nav text-xs text-gray-500 mt-4 flex items-center justify-between gap-2 sm:gap-10 sm:mt-0">
            <a href="#">About Us</a>
            <a href="#">Contact</a>
            <a href="#">Security</a>
            <a href="#">Article</a>
          </div>
          <div className="social-icons flex space-x-4 mt-4 md:mt-0">
            <a href="/" className="footer-icon">
              <img src="assets/icon/x.svg" alt="Twitter" className="w-4 h-4" />
            </a>
            <a href="/" className="footer-icon">
              <img
                src="assets/icon/facebook.svg"
                alt="Facebook"
                className="w-4 h-4"
              />
            </a>
            <a href="/" className="footer-icon">
              <img
                src="assets/icon/linkedin.svg"
                alt="LinkedIn"
                className="w-4 h-4"
              />
            </a>
            <a href="/" className="footer-icon">
              <img
                src="assets/icon/instagram.svg"
                alt="Instagram"
                className="w-4 h-4"
              />
            </a>
            <a href="/" className="footer-icon">
              <img
                src="assets/icon/youtube.svg"
                alt="Instagram"
                className="w-4 h-4"
              />
            </a>
          </div>
        </div>
      </footer>
    </main>
  );
};

export default Footer;
