import { useState } from "react";
import { Link } from "react-router-dom";
import LanguageSwitcher from "./partials/languangeSwitcher";
export const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
  };
  return (
    <main>
      <nav className="bg-white fixed w-full px-5 sm:px-10 md:px-20 z-20 top-0 start-0 shadow-xl shadow-gray-100">
        <div className="max-w-screen-xl flex flex-wrap items-center justify-between py-4 mx-auto">
          <a
            href="https://hulutalent.com"
            className="flex items-center space-x-3"
          >
            <img
              src="assets/icon/logo.png"
              className="h-10"
              alt="Hulutalent Logo"
            />

          </a>
        
          <div className="flex md:order-2 space-x-3 items-center ">
            <div className="md:flex gap-3 items-center hidden">
              <LanguageSwitcher />
              <span className="text-gray-400">|</span>
              <a
                href="https://app.hulutalent.com/login"
                type="button"
                className="text-gray-400 hover:text-warning font-medium text-sm"
              >
                Login
              </a>
              <a
                href="https://app.hulutalent.com"
                type="button"
                className="text-warning bg-black hover:bg-warning hover:text-black focus:ring-4 focus:outline-none focus:ring-dark-tosca font-medium rounded-full px-4 py-2 text-sm text-center"
              >
                Get started for free
              </a>
            </div>
            <div className="md:hidden">
              <LanguageSwitcher />
            </div>
            <button
              onClick={toggleNavbar}
              type="button"
              className="inline-flex items-center p-2 w-10 h-10 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
              aria-controls="navbar-sticky"
              aria-expanded={isOpen}
            >
              <span className="sr-only">Open main menu</span>
              <svg
                className="w-5 h-5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 17 14"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M1 1h15M1 7h15M1 13h15"
                />
              </svg>
            </button>
          </div>
          <div
            className={`items-center justify-between w-full md:flex md:w-auto md:order-1 ${isOpen ? "block" : "hidden"
              }`}
            id="navbar-sticky"
          >
            <ul className="flex flex-col p-4 md:p-0 mt-4 font-medium border border-gray-100 rounded-lg bg-gray-50 md:flex-row md:space-x-8 md:mt-0 md:border-0 md:bg-white">
              <li>
                <Link
                  to="/"
                  className="block py-2 px-3  rounded md:bg-transparent text-gray-400 hover:bg-gray-100 md:hover:bg-transparent md:hover:text-warning md:p-0"
                  aria-current="page"
                >
                  Home
                </Link>
              </li>
              <li>
                <a
                  href="https://app.hulutalent.com/price"
                  className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-warning md:p-0"
                >
                  Price
                </a>
              </li>
              <li className="mb-2">
                <div className="md:hidden mx-2 bg-gray-300 hover:bg-gray-200 rounded p-2 ">
                  <a
                    href="https://app.hulutalent.com/login"
                    type="button"
                    className="text-gray-600 hover:text-warning font-medium text-sm"
                  >
                    Login
                  </a>
                
                </div>
                </li>
              <li>
                <div className="md:hidden mx-2 text-warning bg-black hover:bg-warning hover:text-black focus:ring-4 focus:outline-none focus:ring-dark-tosca font-medium rounded p-2">
                  <a
                    href="https://app.hulutalent.com"
                    type="button"
                    className="text-sm text-center"
                  >
                    Get started for free
                  </a>
                </div>
                </li>
           
            </ul>
          </div>
        </div>
      </nav>
    </main>
  );
};
