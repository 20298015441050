import React from "react";

export default function Jumbotron({
  title = "Default Title",
  subtitle = "Default Subtitle",
  className = "",
  style = {},
  buttons = [],
  children,
}) {
  const defaultClasses =
    "py-8 px-4 max-w-screen-xl text-center rounded-xl";
  return (
    <main>
      <section className="bg-white dark:bg-gray-900 flex justify-center">
        <div className={`${defaultClasses} ${className}`} style={style}>
          <h1 className="mb-6 leading-tight md:leading-snug lg:leading-normal text-2xl md:text-3xl lg:text-4xl text-gray-900  px-4 sm:px-12 lg:px-20 dark:text-white font-semibold ">
            {title}
          </h1>
          <p className="mb-8 text-sm font-normal text-gray-500 lg:text-xl px-4 sm:px-12 lg:px-20 dark:text-gray-400">
            {subtitle}
          </p>
          <div className="flex flex-col space-y-4 sm:flex-row sm:justify-center sm:space-y-0 mb-4">
            {buttons.map((button, index) => (
              <a
                key={index}
                href={button.href || "#"}
                className={button.className}
                onClick={button.onClick}
              >
                {button.label}
                {button.icon && (
                  <button.icon className="w-5 h-5 ml-2" aria-hidden="true" />
                )}
              </a>
            ))}
          </div>
          {children && <div className="mt-8">{children}</div>}
        </div>
      </section>
    </main>
  );
}
